<template>
    <div>
        <el-row v-if="mobile_mode" class="mb-3">

            <a :href="single.picture['picture']" target="_blank" class="mr-3" v-if="single.picture">
                <el-image
                    :style="{maxWidth: '100%'}"
                    style="cursor:zoom-in"
                    :src="single.picture['cover']"
                    fit="scale-down"></el-image>
            </a>

        </el-row>
        <el-row type="flex" class="position-relative">
            <div :style="{flex:'0 0 '+maxWidth,marginRight:'20px'}" v-if="!mobile_mode">
                <a :href="single.picture['picture']" target="_blank" class="mr-3" v-if="single.picture">
                    <el-image
                        :style="{maxWidth: maxWidth, maxHeight: maxWidth}"
                        style="cursor:zoom-in"
                        :src="single.picture['cover']"
                        fit="scale-down"></el-image>
                </a>
            </div>
            <div class="flex-grow-1" :style="!mobile_mode?'border-left: 1px solid #eee; padding-left:20px;':''">
                <el-row style="width:100%">
                    <div><b style="font-size:16px;">{{ single.course_category }}</b></div>
                    <div class="mt-2">
                        {{ single.academy }} | {{ single.teacher_name }}({{ single.teacher_id }}) <span v-if="single.assistant_id">, {{ single.assistant_name }} </span>
                    </div>
                    <div class="mt-2">{{single.district}}</div>
                    <div class="mt-2">
                        <span class="font-weight-bold"> {{
                            single.meeting_code ? single.meeting_code.segmentBy(3) : single.tm_meeting_code.segmentBy(3)
                        }}</span>
                        <span v-if="single.host_key"> | 主持密码:{{single.host_key}}</span>
                        | <i class="el-icon-link text-info"></i> <a class="text-info mr-2"
                                                                                :href="single.start_url?single.start_url:single.tm_start_url"
                                                                                target="_blank">参会</a>
                        <span class="hidden">
                        | <i class="ml-2 el-icon-camera text-success"></i> <a class="text-success"
                                                                              :href="single.streaming"
                                                                              target="_blank">直播</a>
                        </span>
                    </div>
                </el-row>
            </div>
            <div class="closed-logo" v-if="single.is_closed == 1">
                <img src="~@/assets/images/closed.png" alt="closed" />
            </div>
        </el-row>
        <div class="line mt-3" v-if="mobile_mode"></div>

        <div class="mt-4"
             :style="!mobile_mode?'overflow: auto; background-color:#FAFAFA;padding:10px; border:1px solid #eee; border-radius:5px;':''">
            {{ single.brief }}
        </div>


    </div>
</template>

<script>
export default {
    name: "meeting-info",
    computed: {
        maxWidth() {
            return this.mobile_mode ? '100px' : '200px';
        }
    },
    props: {
        single: {
            type: Object, default() {
                return {}
            }
        },
        mobile_mode: {
            type: Boolean, default() {
                return false;
            }
        }
    }
}
</script>

<style lang="scss">
.closed-logo {
    position: absolute;
    top: 0;
    right: 0;
    img {
        width: 64px;
        height: 64px;
    }
}
.hidden {
    display: none;
}
</style>
